var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "m-content" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _c(
          "div",
          {
            staticClass: "m-portlet m-portlet--head-sm",
            attrs: { "m-portlet": "true" }
          },
          [
            _c("div", { staticClass: "m-portlet__head" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "m-portlet__head-tools" }, [
                _c("div", { staticClass: "btn-group btn-group-sm push-10-r" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "btn btn-sm btn-outline-info m-btn m-btn--icon m-btn--icon-only m-btn--custom m-btn--pill copy-btn",
                      attrs: {
                        "data-clipboard": "true",
                        "data-toggle": "m-tooltip",
                        "data-placement": "top",
                        title: "",
                        "data-original-title": "Copy sharable URL"
                      },
                      on: { click: _vm.copyUtilizationUrl }
                    },
                    [_c("i", { staticClass: "la la-copy" })]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "btn-group btn-group-sm push-10-r scope-search"
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn m-btn btn-outline-info btn-sm cursor-pointer",
                        class: { active: _vm.reportTypeFilter === "Talent" },
                        on: {
                          click: function($event) {
                            return _vm.onFilterTypeChange("Talent")
                          }
                        }
                      },
                      [_vm._v("Talent")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn m-btn btn-outline-info btn-sm cursor-pointer",
                        class: { active: _vm.reportTypeFilter === "Vertical" },
                        on: {
                          click: function($event) {
                            return _vm.onFilterTypeChange("Vertical")
                          }
                        }
                      },
                      [_vm._v("Function/Portfolio")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn m-btn btn-outline-info btn-sm cursor-pointer",
                        class: { active: _vm.reportTypeFilter === "Project" },
                        on: {
                          click: function($event) {
                            return _vm.onFilterTypeChange("Project")
                          }
                        }
                      },
                      [_vm._v("Project")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn m-btn btn-outline-info btn-sm cursor-pointer",
                        class: { active: _vm.reportTypeFilter === "Roles" },
                        on: {
                          click: function($event) {
                            return _vm.onFilterTypeChange("Roles")
                          }
                        }
                      },
                      [_vm._v("Roles")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn m-btn btn-outline-info btn-sm cursor-pointer",
                        class: { active: _vm.reportTypeFilter === "Location" },
                        on: {
                          click: function($event) {
                            return _vm.onFilterTypeChange("Location")
                          }
                        }
                      },
                      [_vm._v("Location")]
                    )
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "m-portlet__body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v("Filter By:")
                      ]),
                      _vm._v(" "),
                      _c("div"),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          ref: "selectFilter",
                          staticClass: "select-filter__wrap",
                          attrs: {
                            "value-key": "id",
                            multiple: "multiple",
                            "multiple-limit": _vm.filterLimit,
                            filterable: "",
                            clearable: "",
                            placeholder: "Select upto 2 names"
                          },
                          on: {
                            input: function($event) {
                              return _vm.loadUtilizationData()
                            }
                          },
                          model: {
                            value: _vm.selectedFilterName,
                            callback: function($$v) {
                              _vm.selectedFilterName = $$v
                            },
                            expression: "selectedFilterName"
                          }
                        },
                        _vm._l(_vm.filterOptions, function(filter) {
                          return _c("el-option", {
                            key: filter.id,
                            attrs: { label: filter.name, value: filter.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v("Date Range:")
                      ]),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "fa fa-info-circle font-blue push-2-l",
                        attrs: {
                          "data-toggle": "m-tooltip",
                          "data-placement": "left",
                          title:
                            "Selected dates are auto adjusted to the beginning & end of the month."
                        }
                      }),
                      _vm._v(" "),
                      _c("div"),
                      _vm._v(" "),
                      _c("el-date-picker", {
                        attrs: {
                          "unlink-panels": "",
                          type: "daterange",
                          align: "right",
                          "range-separator": "To",
                          "start-placeholder": "Start date",
                          "end-placeholder": "End date",
                          format: "MM-dd-yyyy",
                          clearable: true,
                          "picker-options": _vm.datePickerOptions,
                          disabled: _vm.showLifetime
                        },
                        on: {
                          input: function($event) {
                            return _vm.loadUtilizationData()
                          }
                        },
                        model: {
                          value: _vm.selectedDateRange,
                          callback: function($$v) {
                            _vm.selectedDateRange = $$v
                          },
                          expression: "selectedDateRange"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.reportTypeFilter !== "Talent" &&
                          _vm.reportTypeFilter !== "Location",
                        expression:
                          "reportTypeFilter !== 'Talent' && reportTypeFilter !== 'Location'"
                      }
                    ],
                    staticClass: "col-md-3"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v("Location:")
                        ]),
                        _vm._v(" "),
                        _c("div"),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            attrs: {
                              "value-key": "id",
                              multiple: "multiple",
                              filterable: "",
                              clearable: "",
                              placeholder: "Choose a Location"
                            },
                            on: {
                              input: function($event) {
                                return _vm.loadUtilizationData()
                              }
                            },
                            model: {
                              value: _vm.locationSelected,
                              callback: function($$v) {
                                _vm.locationSelected = $$v
                              },
                              expression: "locationSelected"
                            }
                          },
                          _vm._l(_vm.locationFilterOptions, function(location) {
                            return _c("el-option", {
                              key: location.id,
                              attrs: {
                                label: location.name,
                                value: location.id
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.reportTypeFilter !== "Project",
                        expression: "reportTypeFilter !== 'Project'"
                      }
                    ],
                    staticClass: "col-md-3"
                  },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v("Threshold in %:")
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "input-group" },
                        [
                          _c("el-input-number", {
                            attrs: {
                              min: 1,
                              max: 100,
                              controls: false,
                              label: "Threshold"
                            },
                            on: { change: _vm.loadUtilizationData },
                            model: {
                              value: _vm.threshold,
                              callback: function($$v) {
                                _vm.threshold = $$v
                              },
                              expression: "threshold"
                            }
                          }),
                          _vm._v(" "),
                          _vm._m(1)
                        ],
                        1
                      )
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.utilizationSummaryDataLoading,
                      expression: "utilizationSummaryDataLoading"
                    }
                  ],
                  staticClass: "row"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "col-xl-7 pad-0",
                      class: {
                        "no-data":
                          !_vm.selectedFilterName.length ||
                          !_vm.utilizationSummary.length
                      }
                    },
                    [
                      _c("div", { staticClass: " m-portlet--full-height " }, [
                        _c(
                          "div",
                          { staticClass: "m-portlet__body" },
                          [
                            _c("gauge-chart", {
                              attrs: {
                                config: _vm.gaugeChartConfig,
                                data: _vm.utilizationSummary
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "col-xl-5 pad-0",
                      class: {
                        "no-data":
                          !_vm.selectedFilterName.length ||
                          !_vm.utilizationSummary.length
                      }
                    },
                    [
                      _c("div", { staticClass: " m-portlet--full-height " }, [
                        _c("div", { staticClass: "m-portlet__body" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "pad-0 m-widget1 font-weight-overide font-size-override"
                            },
                            [
                              _c("div", { staticClass: "m-widget1__item" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row m-row--no-padding align-items-center"
                                  },
                                  [
                                    _vm._m(2),
                                    _vm._v(" "),
                                    _vm._l(_vm.utilizationSummary, function(
                                      item
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: item.name,
                                          staticClass: "col m--align-right"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "m-widget1__number font-bold-500"
                                            },
                                            [_vm._v(_vm._s(item.name))]
                                          )
                                        ]
                                      )
                                    })
                                  ],
                                  2
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "m-widget1__item custom_summary_border"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row m-row--no-padding align-items-center custom-margin"
                                    },
                                    [
                                      _vm._m(3),
                                      _vm._v(" "),
                                      _vm._l(_vm.utilizationSummary, function(
                                        item
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: item.name,
                                            staticClass: "col m--align-right"
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "m-widget1__number"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("decimal")(
                                                      item["loggedHours"] || 0,
                                                      1
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.reportTypeFilter !== "Project",
                                          expression:
                                            "reportTypeFilter !== 'Project'"
                                        }
                                      ]
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "row m-row--no-padding align-items-center custom-margin"
                                        },
                                        [
                                          _vm._m(4),
                                          _vm._v(" "),
                                          _vm._l(
                                            _vm.utilizationSummary,
                                            function(item) {
                                              return _c(
                                                "div",
                                                {
                                                  key: item.name,
                                                  staticClass:
                                                    "col m--align-right"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "m-widget1__number"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("decimal")(
                                                            item[
                                                              "billableHours"
                                                            ] || 0,
                                                            1
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            }
                                          )
                                        ],
                                        2
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "row m-row--no-padding align-items-center"
                                        },
                                        [
                                          _vm._m(5),
                                          _vm._v(" "),
                                          _vm._l(
                                            _vm.utilizationSummary,
                                            function(item) {
                                              return _c(
                                                "div",
                                                {
                                                  key: item.name,
                                                  staticClass:
                                                    "col m--align-right"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "m-widget1__number"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("decimal")(
                                                            item[
                                                              "nonBillableHours"
                                                            ] || 0,
                                                            1
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            }
                                          )
                                        ],
                                        2
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "m-widget1__item custom_summary_border"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row m-row--no-padding align-items-center"
                                    },
                                    [
                                      _vm._m(6),
                                      _vm._v(" "),
                                      _vm._l(_vm.utilizationSummary, function(
                                        item
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: item.name,
                                            staticClass: "col m--align-right"
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "m-widget1__number"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("decimal")(
                                                      item["billedHours"] || 0,
                                                      1
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "m-widget1__item custom_summary_border"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row m-row--no-padding align-items-center"
                                    },
                                    [
                                      _vm._m(7),
                                      _vm._v(" "),
                                      _vm._l(_vm.utilizationSummary, function(
                                        item
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: item.name,
                                            staticClass: "col m--align-right"
                                          },
                                          [
                                            _vm.reportTypeFilter === "Project"
                                              ? [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "m-widget1__number"
                                                    },
                                                    [_vm._v("N/A")]
                                                  )
                                                ]
                                              : [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "m-widget1__number"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("decimal")(
                                                            item[
                                                              "totalCapacity"
                                                            ] || 0,
                                                            1
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                          ],
                                          2
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "m-widget1__item" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row m-row--no-padding align-items-center"
                                  },
                                  [
                                    _c("span", {
                                      ref: "tooltipIcon",
                                      staticClass:
                                        "fa fa-info-circle font-blue push-2-l",
                                      attrs: {
                                        "data-toggle": "m-tooltip",
                                        "data-placement": "left",
                                        title: _vm.getTooltipTitle()
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("div"),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col" }, [
                                      _c(
                                        "h3",
                                        { staticClass: "m-widget1__title" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.filterName) +
                                              " wise Utilization"
                                          )
                                        ]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(_vm.utilizationSummary, function(
                                      item
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: item.name,
                                          staticClass: "col m--align-right"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "m-widget1__number",
                                              class: {
                                                "m--font-danger":
                                                  item[_vm.utilizationProp] >
                                                  100
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                            " +
                                                  _vm._s(
                                                    _vm._f("decimal")(
                                                      item[
                                                        _vm.utilizationProp
                                                      ] || 0,
                                                      1
                                                    )
                                                  ) +
                                                  " %\n                          "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    })
                                  ],
                                  2
                                )
                              ])
                            ]
                          )
                        ])
                      ])
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: " m-portlet--head-sm detailed",
                  attrs: { "m-portlet": "true" }
                },
                [
                  _c("div", { staticClass: "m-portlet__head" }, [
                    _c("div", { staticClass: "m-portlet__head-caption" }, [
                      _c("div", { staticClass: "m-portlet__head-title" }, [
                        _c("h3", { staticClass: "m-portlet__head-text" }, [
                          _vm._v(
                            "\n                    Hours per " +
                              _vm._s(_vm.viewType) +
                              "\n                  "
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.reportTypeFilter === "Group" ||
                              _vm.reportTypeFilter === "Vertical",
                            expression:
                              "reportTypeFilter === 'Group' || reportTypeFilter === 'Vertical'"
                          }
                        ],
                        staticClass: "m-portlet__head-tools"
                      },
                      [
                        _c("ul", { staticClass: "m-portlet__nav" }, [
                          _c(
                            "li",
                            {
                              staticClass:
                                "m-portlet__nav-item m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push",
                              attrs: { "m-dropdown-toggle": "hover" }
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "m-portlet__nav-link m-dropdown__toggle dropdown-toggle btn m-btn--sm m-btn--pill btn-secondary m-btn m-btn--label-brand m--font-brand"
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(_vm.viewType) +
                                      " View\n                    "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "m-dropdown__wrapper width-150"
                                },
                                [
                                  _c("span", {
                                    staticClass:
                                      "m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "m-dropdown__inner" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "m-dropdown__body" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "m-dropdown__content"
                                            },
                                            [
                                              _c(
                                                "ul",
                                                { staticClass: "m-nav" },
                                                [
                                                  _c(
                                                    "li",
                                                    {
                                                      staticClass:
                                                        "m-nav__item cursor-pointer"
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "m-nav__link view-option-popup",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.handleViewChange(
                                                                "Talent"
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "m-nav__link-text"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Talent View"
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "li",
                                                    {
                                                      staticClass:
                                                        "m-nav__item cursor-pointer"
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "m-nav__link view-option-popup",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.handleViewChange(
                                                                "Project"
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "m-nav__link-text"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Project View"
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "m-portlet__body" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12" },
                        [
                          _c(
                            "el-tabs",
                            {
                              on: { "tab-click": _vm.handleTabChange },
                              model: {
                                value: _vm.activeTab,
                                callback: function($$v) {
                                  _vm.activeTab = $$v
                                },
                                expression: "activeTab"
                              }
                            },
                            _vm._l(_vm.utilizationDetailed, function(item) {
                              return _c("el-tab-pane", {
                                key: item.name,
                                attrs: { name: item.name, label: item.name }
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "clearfix pull-right margin-top-52"
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.utilizationDetailedDataLoading,
                              expression: "utilizationDetailedDataLoading"
                            }
                          ],
                          staticClass: "col-md-12",
                          class: {
                            "no-data":
                              !_vm.selectedFilterName.length ||
                              !_vm.currentDetailedCollection.length
                          }
                        },
                        [
                          _c("bar-chart", {
                            attrs: {
                              series: _vm.barChartSeries,
                              categoryKey: "name",
                              data: _vm.currentDetailedCollection,
                              xAxisLabel: _vm.viewType + "s",
                              yAxisLabel: _vm.yAxisLabel
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.utilizationDetailedDataLoading,
                              expression: "utilizationDetailedDataLoading"
                            }
                          ],
                          staticClass: "col-md-12",
                          class: {
                            "no-data":
                              !_vm.selectedFilterName.length ||
                              !_vm.currentDetailedCollection.length
                          }
                        },
                        [
                          _c(
                            "el-table",
                            {
                              key: _vm.tableKey,
                              ref: "detailedTable",
                              staticStyle: { width: "100%" },
                              attrs: {
                                border: "",
                                stripe: "",
                                data: _vm.currentDetailedCollection,
                                "min-height": "100",
                                height: _vm.detailedTableHeight
                              }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "name",
                                  label:
                                    _vm.viewType == "Project"
                                      ? "Project Name"
                                      : "Talent Name",
                                  "min-width": "240",
                                  "show-overflow-tooltip": true,
                                  resizable: true
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "m-link m--font-bolder link-table",
                                            attrs: {
                                              target: "_blank",
                                              href: _vm.handleNameClick(
                                                scope.row.id
                                              )
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                          " +
                                                _vm._s(scope.row.name) +
                                                "\n                        "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _vm._v(" "),
                              _vm.viewType === "Project"
                                ? _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      prop: "poolType",
                                      label: "Type",
                                      "min-width": "240",
                                      "show-overflow-tooltip": true,
                                      resizable: true
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "m--font-bolder link-table"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(
                                                        scope.row.poolType
                                                      ) +
                                                      "\n                      "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2853701878
                                    )
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.reportTypeFilter === "Project"
                                ? _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      prop: "leavesHours",
                                      label: "Holiday & Vacation Hours",
                                      "min-width": "180",
                                      "show-overflow-tooltip": true,
                                      resizable: false
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(
                                                    _vm._f("decimal")(
                                                      scope.row.leavesHours,
                                                      1
                                                    )
                                                  ) +
                                                  "\n                      "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      301449097
                                    )
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "billedHours",
                                  align: "center",
                                  label: "Planned Hours",
                                  "min-width": "160",
                                  "show-overflow-tooltip": true,
                                  resizable: false
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm._f("decimal")(
                                                scope.row.billedHours,
                                                1
                                              )
                                            ) +
                                            "\n                      "
                                        )
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "loggedHours",
                                  label: "Total Logged Hours",
                                  "min-width": "160",
                                  "show-overflow-tooltip": true,
                                  resizable: false
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm._f("decimal")(
                                                scope.row.loggedHours,
                                                1
                                              )
                                            ) +
                                            "\n                      "
                                        )
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _vm._v(" "),
                              (_vm.reportTypeFilter === "Vertical" ||
                                _vm.reportTypeFilter === "Roles" ||
                                _vm.reportTypeFilter === "Location") &&
                              _vm.viewType === "Talent"
                                ? _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      prop: "billableHours",
                                      label: "Billable Logged Hours",
                                      "min-width": "160",
                                      "show-overflow-tooltip": true,
                                      resizable: false
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(
                                                    _vm._f("decimal")(
                                                      scope.row.billableHours,
                                                      1
                                                    )
                                                  ) +
                                                  "\n                      "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1373724064
                                    )
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              (_vm.reportTypeFilter === "Vertical" ||
                                _vm.reportTypeFilter === "Roles" ||
                                _vm.reportTypeFilter === "Location") &&
                              _vm.viewType === "Talent"
                                ? _c(
                                    "el-table-column",
                                    {
                                      attrs: {
                                        align: "center",
                                        prop: "nonBillableHours",
                                        "min-width": "160",
                                        "show-overflow-tooltip": true,
                                        resizable: false
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(scope) {
                                              return [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(
                                                      _vm._f("decimal")(
                                                        scope.row
                                                          .nonBillableHours,
                                                        1
                                                      )
                                                    ) +
                                                    "\n                      "
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        4099631631
                                      )
                                    },
                                    [
                                      _c("template", { slot: "header" }, [
                                        _vm._v(
                                          "\n                      Non-Billable "
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " Logged Hours\n                    "
                                        )
                                      ])
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-table-column",
                                {
                                  attrs: {
                                    align: "center",
                                    prop: _vm.utilizationProp,
                                    "min-width": "180",
                                    "show-overflow-tooltip": true,
                                    resizable: false
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(
                                                _vm._f("decimal")(
                                                  scope.row[
                                                    _vm.utilizationProp
                                                  ],
                                                  2
                                                )
                                              ) +
                                              " %\n                      "
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c("template", { slot: "header" }, [
                                    _c("div", [
                                      _c("span", {
                                        staticClass:
                                          "fa fa-info-circle font-blue push-2-l",
                                        attrs: {
                                          "data-toggle": "m-tooltip",
                                          "data-placement": "left",
                                          title:
                                            _vm.reportTypeFilter === "Project"
                                              ? "( logged hours/ planned hours)*100"
                                              : "( logged hours/ totalCapacity)*100"
                                        }
                                      }),
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(_vm.filterName) +
                                          " "
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " wise Utilization\n                      "
                                      )
                                    ])
                                  ])
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ]
              )
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "m-portlet__head-caption" }, [
      _c("div", { staticClass: "m-portlet__head-title" }, [
        _c("span", { staticClass: "m-portlet__head-icon" }, [
          _c("i", { staticClass: "flaticon-time-1" })
        ]),
        _vm._v(" "),
        _c("h3", { staticClass: "m-portlet__head-text" }, [
          _vm._v("\n                Talent Utilization Report\n              ")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-append" }, [
      _c("span", { staticClass: "input-group-text" }, [_vm._v("%")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("h3", { staticClass: "m-widget1__title" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("h3", { staticClass: "m-widget1__title" }, [
        _vm._v("Total Logged Hours")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("h3", { staticClass: "m-widget1__title" }, [
        _vm._v("Billable Logged Hours")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("h3", { staticClass: "m-widget1__title" }, [
        _vm._v("Non-Billable Logged Hours")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("h3", { staticClass: "m-widget1__title" }, [_vm._v("Planned Hours")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("h3", { staticClass: "m-widget1__title" }, [_vm._v("Total Capacity")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }